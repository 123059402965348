import { type PropsWithChildren } from 'react';
import { Box, Link, type LinkT, Text, type TextT } from '@mentimeter/ragnar-ui';
import * as React from 'react';

const wwwUrl = globalThis.__mentimeterEnv['NEXT_PUBLIC_WWW_URL'];

export const FooterContainer = ({ children }: PropsWithChildren<object>) => {
  return (
    <Box mt="space6" width="100%" alignItems="center">
      <Box
        as="footer"
        flexDirection="column"
        width="100%"
        alignItems="center"
        gap={2}
      >
        {children}
      </Box>
    </Box>
  );
};

FooterContainer.Text = (props: TextT) => <Text textAlign="center" {...props} />;
FooterContainer.TermsText = (props: TextT) => (
  <Text textAlign="center" fontSize="87.5" {...props} />
);

FooterContainer.TermsOfUseLink = (props: LinkT) => (
  <Link
    href={`${wwwUrl}/terms`}
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  />
);
FooterContainer.PoliciesLink = (props: LinkT) => (
  <Link href="/policies" target="_blank" rel="noopener noreferrer" {...props} />
);
