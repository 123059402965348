import * as React from 'react';
import { withTheme } from '@mentimeter/ragnar-react';
import { Clickable } from '@mentimeter/ragnar-ui';
import type { DSC } from '@mentimeter/ragnar-dsc';
import type { Reaction as ReactionType } from '@mentimeter/http-clients';
import { getReaction } from './Reaction/helpers';

interface Props {
  reaction: ReactionType;
  onClick: (reaction: ReactionType) => void;
  vote: ReactionType | undefined;
  themeIndex: number;
  // Theme
  theme: DSC;
}

function Reaction({ reaction, onClick, vote, themeIndex, theme }: Props) {
  return (
    <Clickable
      p="space3"
      borderRadius="full"
      aria-label={`Send ${reaction} reaction to the presenter!`}
      key={reaction}
      borderWidth={2}
      borderStyle="solid"
      extend={() => {
        // The index is not zero indexed
        const themeColors = theme.visualizationColors;
        const bg =
          vote === reaction
            ? themeColors?.fillColors[themeIndex]
            : 'transparent';
        return {
          backgroundColor: bg,
          borderColor: themeColors?.fillColors[themeIndex],
          ':hover': {
            backgroundColor: bg,
            borderColor: themeColors?.fillColors[themeIndex],
          },
        };
      }}
      onClick={(e) => {
        // Prevent double tap zooming
        e.preventDefault();
        onClick(reaction);
      }}
    >
      {getReaction(
        reaction,
        vote === reaction
          ? theme.visualizationColors.backgroundColor
          : theme.visualizationColors.fillColors[
              themeIndex % theme.visualizationColors.fillColors.length
            ]!,
      )}
    </Clickable>
  );
}

export default withTheme(Reaction);
