import * as React from 'react';
import { postIntegrationMessage } from '@mentimeter/integrations-shared';
import { useIntegration } from '../../../features/integrations';
import { FooterContainer } from './FooterContainer';

const wwwUrl = globalThis.__mentimeterEnv['NEXT_PUBLIC_WWW_URL'];
const canonicalHost = globalThis.__mentimeterEnv['NEXT_PUBLIC_CANONICAL_HOST'];

function sendOpenUrlEvent(url: string) {
  postIntegrationMessage(
    window?.parent,
    {
      type: 'open-url',
      payload: url,
    },
    wwwUrl || '*',
  );
}

/**
 * Used in try mode and in integrations (e.g. zoom, msteams)
 */
export const EmbeddedFooter = () => {
  const { integrationId, isInternalIntegration } = useIntegration();
  const isIntegrated = Boolean(integrationId);

  return (
    <>
      <FooterContainer.Text color={isIntegrated ? 'textWeaker' : 'primary'}>
        Powered by Mentimeter
      </FooterContainer.Text>
      <FooterContainer.TermsText>
        By using Mentimeter you accept our{' '}
        <FooterContainer.TermsOfUseLink
          onClick={(e) => {
            // If our integration, open send event instead
            if (isInternalIntegration) {
              e.preventDefault();
              sendOpenUrlEvent(`${wwwUrl}/terms`);
            }
          }}
        >
          terms of use
        </FooterContainer.TermsOfUseLink>{' '}
        and{' '}
        <FooterContainer.PoliciesLink
          onClick={(e) => {
            // If our integration, open send event instead
            if (isInternalIntegration) {
              e.preventDefault();
              sendOpenUrlEvent(`${canonicalHost}/policies`);
            }
          }}
        >
          policies
        </FooterContainer.PoliciesLink>
        .
      </FooterContainer.TermsText>
    </>
  );
};
