import * as React from 'react';
import { useIntegration } from '../../../features/integrations';
import { isTryMode } from '../../../helpers/isTryMode';
import { EmbeddedFooter } from './EmbeddedFooter';
import { FooterContainer } from './FooterContainer';
import { DefaultFooter } from './DefaultFooter';

interface Props {
  utmUrlParams?: string;
  onPoweredByClick?: () => void;
  context?: 'Home' | undefined;
}

const Footer = ({ context, utmUrlParams, onPoweredByClick }: Props) => {
  const { integrationId } = useIntegration();
  const isIntegrated = Boolean(integrationId);

  const tryModeOrIntegrated = isTryMode() || isIntegrated;
  return (
    <FooterContainer>
      {tryModeOrIntegrated ? (
        <EmbeddedFooter />
      ) : (
        <DefaultFooter
          context={context}
          utmUrlParams={utmUrlParams}
          onMentimeterLinkClick={onPoweredByClick}
        />
      )}
    </FooterContainer>
  );
};

export default Footer;
